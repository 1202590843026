import { get, post } from '../../common/request';
import { pagesToNodes } from '../../sitemap/pages-to-nodes';

export function searchOtherSitemaps({ sitemapId, query }) {
  const params = new URLSearchParams({
    status: 'finished',
    exclude: sitemapId,
    exclude_kind: ['scheduled', 'scheduled_compared'],
  });

  if (query) {
    params.append('q', query);
  }

  const url = `/api/v1/sitemaps/${sitemapId}/team/sitemaps.json?${params.toString()}`;

  return get(url);
}

function appendTagging({ nodes, nodesTagging }) {
  if (!nodesTagging) {
    return nodes;
  }

  nodes.forEach((node) => {
    const tagging = nodesTagging[node.id];
    if (tagging) {
      // eslint-disable-next-line no-param-reassign
      node.tagging = tagging;
    }
  });

  return nodes;
}

export function sitemapNodes({ sitemapId }) {
  return get(`/api/v1/sitemaps/${sitemapId}/tree_state/nodes.json`).then((response) => {
    const { nodes, nodesTagging, pages } = response;
    if (nodes) {
      return appendTagging({ nodes, nodesTagging });
    }

    return pagesToNodes(pages);
  });
}

export function trackImport(sitemapId, { sourceId, flatImport, items }) {
  const data = {
    import: {
      source_id: sourceId,
      flat_import: flatImport,
      items_attributes: items,
    },
  };

  return post(`/api/v1/sitemaps/${sitemapId}/page_imports.json`, { data });
}
