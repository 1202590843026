import { linkVertical, linkHorizontal } from 'd3-shape';

import { dimensions, captionStyles, treeContainer, folderTextStyles } from './config';
import { absoluteThumbUrl, treeNodeThumbUrl } from '../utils';

/* global $ */
export const translate = (x, y, isVertical) => {
  if (isVertical) {
    return `translate(${x},${y})`;
  }

  return `translate(${y},${x})`;
};

export const viewPort = () => {
  return [$(treeContainer).width(), $(document).height() * 0.85];
};

export const sortCriteria = (a, b) => {
  const { id: idA, index: indexA } = a.data;
  const { id: idB, index: indexB } = b.data;

  const order = indexA - indexB;

  if (order !== 0) {
    return order;
  }

  // Then sort by id
  if (idA < idB) {
    return -1;
  }
  if (idA > idB) {
    return 1;
  }

  return 0;
};

/**
 * Point where the center of the drag & drop placeholder should be.
 * Relative to node x,y (upper left corner)
 *
 * @param {*} isVertical - Is the tree being rendered vertical or not?
 * @param {*} dimensions - config settings
 */
export const dragAndDropPointPosition = (isVertical) => {
  const { imageContainer, captionLine } = dimensions;
  const { width: imageContainerWidth, height: imageContainerHeight } = imageContainer;
  const { height: captionLineHeight } = captionLine;

  const displacementX = isVertical ? 0 : -imageContainerWidth / 2;
  const displacementY = isVertical ? 0 : (imageContainerHeight + captionLineHeight) / 2;

  return [displacementX, displacementY];
};

/**
 * Link path generator.
 *
 * When it's called with an object with { source, target } structure
 * it produces a curved (diagonal) path from parent to the child nodes.
 *
 * @param {*} isVertical - Is the tree being rendered vertical or not?
 * @param {*} dragAndDropDisplacement - Point where center of drag & drop is (result of calling `dragAndDropPointPosition`)
 */
export const linkPath = (isVertical, dragAndDropDisplacement) => {
  const [displacementX, displacementY] = dragAndDropDisplacement;

  let link;
  if (isVertical) {
    link = linkVertical()
      .x((d) => d.x + displacementX)
      .y((d) => d.y + displacementY);
  } else {
    link = linkHorizontal()
      .x((d) => d.y + displacementX)
      .y((d) => d.x + displacementY);
  }

  return link;
};

/**
 * Given a basepath generates a function that return the url of the screenshot for a given node.
 *
 * @param {*} baseScreenshotPath - bath path (optional)
 */
export const screenshotUrl = (baseScreenshotPath) => (d) => {
  const { isFolder, screenshot, thumbnail } = d.data;

  if (isFolder || !screenshot) {
    return '';
  }

  const imgPath = thumbnail || treeNodeThumbUrl(screenshot);
  return absoluteThumbUrl(imgPath, baseScreenshotPath);
};

/**
 * Convert style object into a css string.
 * The string is one line of multiple css declarations.
 * @param {*} styles
 */
export const pageTitleCss = (extra = {}) => {
  const styles = { ...captionStyles, ...extra };

  const css = `
    font-family: ${styles.fontFamily};
    font-size: ${styles.fontSize}px;
    line-height: ${styles.lineHeight};
    font-weight: ${styles.fontWeight};
    text-transform: ${styles.textTransform};
    padding: ${styles.paddingY}px ${styles.paddingX}px;
    overflow-wrap: ${styles.overflowWrap};
  `;

  // Return one line
  return css.replace(/\n/g, '').replace(/\s\s+/g, ' ').trim();
};

/**
 * Convert style object into a css string.
 * The string is one line of multiple css declarations.
 * @param {*} styles
 */
export const folderTitleCss = (extra = {}) => {
  const styles = { ...folderTextStyles, ...extra };

  const css = `
    font-family: ${styles.fontFamily};
    font-size: ${styles.fontSize}px;
    line-height: ${styles.lineHeight};
    font-weight: ${styles.fontWeight};
    text-align: ${styles.textAlign};
    padding: ${styles.paddingY}px ${styles.paddingX}px;
    overflow-wrap: ${styles.overflowWrap};
  `;

  // Return one line
  return css.replace(/\n/g, '').replace(/\s\s+/g, ' ').trim();
};

export const squareTagsCss = (container) => {
  const { tagSquare: styles } = dimensions;

  const css = `
    ${container} .tags > div {
      margin: ${styles.margin}px;
    }

    ${container} .tags .tag {
      width: ${styles.size}px;
      height: ${styles.size}px;
      font-size: ${styles.fontSize}px;
    }
  `;

  // Return one line
  return css.replace(/\n/g, '').replace(/\s\s+/g, ' ').trim();
};

export const ballTagsCss = (container) => {
  const { tagBall: styles } = dimensions;

  const css = `
  ${container} .tags-balls > div {
      margin: ${styles.margin}px;
    }

    ${container} .tags-balls .tag {
      width: ${styles.size}px;
      height: ${styles.size}px;
    }
  `;

  // Return one line
  return css.replace(/\n/g, '').replace(/\s\s+/g, ' ').trim();
};

export const selectActionCss = (container) => {
  const { tagSquare: styles } = dimensions;

  const css = `
    ${container} .action.select > div {
      margin: ${styles.margin}px ${styles.margin}px ${styles.margin}px auto;

      width: ${styles.size}px;
      height: ${styles.size}px;
      font-size: ${styles.fontSize}px;
    }
  `;

  // Return one line
  return css.replace(/\n/g, '').replace(/\s\s+/g, ' ').trim();
};
